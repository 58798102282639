@import "../../Styles/theme.scss";

.image {
  margin: 0;
  object-fit: contain;
  height: 100%;
  width: 80px;
  @media (max-width: $md) {
    height: 76px;
  }
}
