@import "../../Styles/theme";

.suaz-bg {
  label {
    border: 1px solid #ced4da;
  }

  label::after {
    background-color: #ffce2b !important;
    color: #333333;
    height: auto;
    border-left: none;
    padding: 0.375rem 3rem;
    content: attr(data-buttontext);
  }
}

.proFormations-bg {
  label {
    border: 1px solid #ced4da;
  }

  label::after {
    background-color: #ff435f !important;
    color: #fff;
    height: auto;
    border-left: none;
    padding: 0.375rem 1rem;
    content: attr(data-buttontext);

    @media (min-width: $md) {
      padding: 0.375rem 3rem;
    }
  }
}

.justLimited-bg {
  label {
    border: 1px solid #ced4da;
  }

  label::after {
    background-color: #faba43 !important;
    color: #000;
    height: auto;
    border-left: none;
    padding: 0.375rem 3rem;
    content: attr(data-buttontext);
  }
}
