@import url("https://use.fontawesome.com/releases/v5.15.2/css/all.css");
@import "../../Styles/theme";

@mixin bootstrapOverrides {
  // Override some of bootstraps styling as Draft.js wraps blocks in span
  h1 span {
    font-size: 42px !important;
  }

  h2 span {
    font-size: 34px !important;
  }

  h3 span {
    font-size: 27px !important;
  }
}

@mixin editor {
  box-sizing: border-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.editor {
  cursor: text;
  @include editor;
  @include bootstrapOverrides;
}

:global(.bg-primary) {
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
}

.editorReadonly {
  cursor: default;
  @include editor;
  @include bootstrapOverrides;
}

.editorReadonly :global(.public-DraftEditor-content) {
  caret-color: transparent;
  padding: 10px;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 200px;
  padding: 10px;
}

:global(.public-DraftStyleDefault-ul) {
  list-style-type: none;
  margin-top: 1rem;
  padding: 0;
}

:global(.public-DraftStyleDefault-unorderedListItem) {
  padding-left: 1rem;
  padding-top: 5px;
}

:global(ul li div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr) {
  margin-top: -1.1rem;
  margin-left: 0.9rem;
  color: $main-text;
}

:global(.public-DraftStyleDefault-unorderedListItem):before {
  content: '\f058';
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  margin-left: -1rem;
}

:global(.large-mode > div) {
  margin-top: -1.55rem !important;
}

// Draft.js placeholder is a hack and isn't really a placeholder. :global can't be used with & btw
.editor :global(.public-DraftEditorPlaceholder-root) {
  position: relative;
}

// Make the 'placeholder' line up with content to avoid js hacks as seen in Draft.js examples https://github.com/facebook/draft-js/tree/master/examples/draft-0-10-0/rich */
.editor :global(.public-DraftEditorPlaceholder-inner) {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #999;
}

/* stylelint-enable selector-pseudo-class-no-unknown */
