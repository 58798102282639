@import "../../Styles/theme";

.formFooter {
    max-width: $formMaxWidth;
    padding-bottom: 20px;

    button {
        @media (max-width: $md) {
            width: 48% !important;
        }
    }

    .largeButton {
        @media (max-width: $md) {
            margin-left: 0 !important;
            width: 100% !important;
        }
    }

    .scrollbarBottomBlur {
        height: 20px;
        position: relative;
        top: -20px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    .hideScrollbarBottomBlur {
        z-index: -1000;
        opacity: 0;
        transition: opacity 500ms linear;
    }

    .continueLoadingIcon {
        position: relative;
        top: 1px;
    }
}
