@import "../../Styles/theme.scss";

.card {
    min-height: 310px;
    width: 317px;
    text-align: left;
    border: none;

    &:hover {
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.25) !important;
        transition: box-shadow 0.3s ease-in-out;
    }
}

.inBasket {
    position: relative;

    svg {
        background-color: white;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        right: -10px;
    }
}

.disabledPrice:disabled {
    background-color: transparent !important;
}

.productSelected {
    background-color: $light;
}

.inputLabel {
    margin-right: 0 !important;
}

.frequencyText {
    position: relative;
    top: 5px;
}

.infoButton {
    height: 25px;
    transition: 300ms ease-in-out;

    &:hover {
        color: $primary !important;
    }
}

.clearButton {
    right: 5%;
    bottom: 16px;
    width: 90% !important;
}
