.react-date-picker__wrapper {
    border: none !important;
}

.react-calendar__navigation__label {
    border-radius: 5px;
}

.react-calendar__navigation__label__labelText--from {
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.2em;
}

.react-calendar__tile--now {
    background-color: white !important;
    border: solid 2px $primary !important;
    border-radius: 5px;
    font-weight: 600;
}

.react-calendar__tile:disabled {
    border-radius: 5px;
}

.react-calendar__tile--active {
    font-weight: 700;
    color: white !important;
    background-color: $primary !important;
}

.react-calendar__month-view__days {
    .react-calendar__tile {
        border-radius: 50%;

        &:hover {
            color: white !important;
            background-color: $primary !important;
        }
    }

    .react-calendar__month-view__days__day--weekend {
        color: $primary;
    }
}

.react-calendar__month-view__weekdays__weekday {
    abbr {
        text-decoration: none !important;
    }
}

.react-calendar__year-view {
    .react-calendar__tile {
        padding: 1.5em 0.5em !important;
    }

    .react-calendar__year-view__months__month {
        &:hover {
            color: white !important;
            background-color: $primary !important;
            border-radius: 5px;
        }
    }
}

.react-calendar__decade-view {
    .react-calendar__tile {
        padding: 1.5em 0.5em !important;
    }

    .react-calendar__decade-view__years__year {
        &:hover {
            color: white !important;
            background-color: $primary !important;
            border-radius: 5px;
        }
    }
}

.react-calendar__century-view {
    .react-calendar__tile {
        padding: 1.5em 0.5em !important;
    }

    .react-calendar__century-view__decades__decade {
        &:hover {
            color: white;
            background-color: $primary !important;
            border-radius: 5px;
        }
    }
}

.react-date-picker__inputGroup__input:invalid {
    background-color: transparent !important;
}
