@import "../../Styles/theme.scss";

.infoButton {
  height: 25px;
  transition: 300ms ease-in-out;
  cursor: pointer;
  &:hover {
    color: $primary !important;
  }
}
