$formHeaderHeight: 138px;
$formFooterHeight: 110px;
$formMaxWidth: 750px;
$footerHeight: 76px;
$termsAndConditions: 26px;
// Bootstrap theme overrides
$registered-office-height: 300px;
$link-color: #333333;
$theme-colors: (
  "muted": #e5e5e5,
  "info": #ffc483,
  "primary": #ffce2b,
  "secondary": #e5e5e5,
  "danger": #bb5360,
  "inactive": #ddd,
  "light": #f5f5f5,
  "accent": #9b9b9b,
  "success": #00ce77,
  // custom theme
  // color of the progress bar
  "progress": #ffce2b,
  // color of the form headers
  "header": #333333,
  // color of the text throughout
  "main": black,
  // color of the text in the info section
  "minor": white,
  // color of the background behind the logo
  "brand-bg": #ffce2b,
  // brand colours specific to bsc
  "bscPrimary": #333333
);

// Variables
$main-text: #333;
$brand: #ffce2b;
$light-border: #d8d8d8;
$secondary-brand-click: #7f7f7f;

$primary-color: map_get($theme-colors, primary);
$accent-color: map_get($theme-colors, accent);
$light-color: map_get($theme-colors, light);
$info-color: map_get($theme-colors, info);
$success-color: map_get($theme-colors, success);
$bscBrand: #107199;

// Bootstrap default value overrides
$border-width: 2px;
$font-weight-bold: 600;

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "./calendar";

$text-placeholder: #bbb;
$disabled-bg: #d8d8d8;

/* Bootstrap break point sizes */
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.ScrollbarsCustom-Track {
  width: 6px !important;
  top: 5px !important;
  height: calc(100% - 10px) !important;
}

.ScrollbarsCustom-Wrapper {
  right: 6px !important;
}

.ScrollbarsCustom-Thumb {
  background: rgba(0, 0, 0, 0.2) !important;
}

.ScrollbarsCustom-Content {
  display: flex;
  flex-direction: column;
}
