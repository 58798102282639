@import "Styles/theme";

.container {
  min-width: 320px;

  .formWrapper {
    box-shadow: 0 -6px 6px 0 rgba(0, 0, 0, 0.25);
  }

  .formContainer {
    max-width: $formMaxWidth;
    height: calc(100vh - (#{$formHeaderHeight} + #{$formFooterHeight}));
  }

  .formFooter {
    max-width: $formMaxWidth;
    padding-bottom: 20px;

    button {
      @media (max-width: $md) {
        width: 100% !important;
      }
    }

    .scrollbarBottomBlur {
      height: 20px;
      position: relative;
      top: -20px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    .hideScrollbarBottomBlur {
      z-index: -1000;
      opacity: 0;
      transition: opacity 500ms linear;
    }

    .continueLoadingIcon {
      position: relative;
      top: 1px;
    }
  }

  .leftInfoContainer-suaz {
    background-image: url(./Assets/Images/Backgrounds/suaz-background.jpg);
    background-size: cover;
    max-width: 800px;
    transition: all 300ms;
    @media (min-width: $md) {
      width: 40%;
    }

    .informationContent {
      h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
      }

      h3 {
        font-size: 1.3rem;
        margin-top: 2rem;
      }
    }
  }

  .leftInfoContainer-justLimited {
    background-image: url(./Assets/Images/Backgrounds/justLimited-background.jpg);
    background-size: cover;
    max-width: 800px;
    transition: all 300ms;
    @media (min-width: $md) {
      width: 40%;
    }

    .informationContent {
      h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
      }

      h3 {
        font-size: 1.3rem;
        margin-top: 2rem;
      }
    }
  }

  .leftInfoContainer-proFormations {
    background-image: url(./Assets/Images/Backgrounds/proFormations-background.png);
    background-size: cover;
    max-width: 800px;
    transition: all 300ms;
    @media (min-width: $md) {
      width: 40%;
    }

    .informationContent {
      h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
      }

      h3 {
        font-size: 1.3rem;
        margin-top: 2rem;
      }
    }
  }
}

.popup-suaz {
  background-image: url(./Assets/Images/Backgrounds/suaz-background.jpg);
  min-width: 280px;

  .popupContent {
    overflow-y: auto;
  }
}

.popup-justLimited {
  background-image: url(./Assets/Images/Backgrounds/justLimited-background.jpg);
  min-width: 280px;

  .popupContent {
    overflow-y: auto;
  }
}

.popup-proFormations {
  background-image: url(./Assets/Images/Backgrounds/proFormations-background.png);
  min-width: 280px;

  .popupContent {
    overflow-y: auto;
  }
}

.header {
  height: 74px;
  max-width: 750px;
}

:global(.footerGap) {
  padding-bottom: $footerHeight;
}

:global(.footerGapTC) {
  padding-bottom: $footerHeight + $termsAndConditions;
}
