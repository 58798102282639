.progressBar {
    width: 100%;
    height: 10px;
    overflow-x: hidden;

    .progressBarProgress {
        transition: all 0.4s ease;
        width: 0;
        height: 100%;
    }
}
