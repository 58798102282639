@import "../../Styles/theme";

.image {
  height: 235px;
  width: 42vw;
  @media(min-width: $lg) {
    width: 240px;
  }
}

.info {
  width: 55%;
}

.button {
  @media(min-width: $md) {
    max-width: 296px;
  }
}

