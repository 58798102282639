@import "src/Styles/theme";

.img {
    height: auto;
    width: auto;
    max-height: 40vh;
    max-width: 100%;
}

.moreInformation {
    max-height: 44vh;
    @media (max-width: 320px) { // We don't have a breakpoint for iPhone SE
        max-height: 40vh;
    }
}

.moreInformationNoGallery {
    max-height: 88vh;
    @media (max-width: $md) {
        max-height: 75vh;
    }
}

.logo {
    height: 30px;
}
