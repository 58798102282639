@import "theme.scss";

html,
body {
    height: 100%;
}

button:disabled {
  background-color: $disabled-bg !important;
  border-color: $disabled-bg !important;
  opacity: 1;
}
