@import "../../Styles/theme";

.header {
  background: radial-gradient(circle at 50% 30%, #819cb5, #005679);

  .signUpContainer {
    display: grid;
    grid-template-columns: auto;
    @media (min-width: $md) {
      grid-template-columns: auto 300px;
    }
  }

  .logo {
    height: 130px;
    width: 130px;
  }
}

.loveBsc {
  display: grid;
  grid-template-columns: auto;
  min-height: 500px;
  @media (min-width: $md) {
    grid-template-columns: 38% auto;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: lighten($info-color, 5%);
    z-index: 1;
  }

  h1 {
    font-size: 53px;
    color: $bscBrand;
  }

  div {
    z-index: 2;
  }

  .cards {
    display: grid;
    grid-template-columns: 100%;
    column-gap: 2rem;
    max-width: 900px;
    @media (min-width: $lg) {
      grid-template-columns: 50% 50%;
    }
  }

  svg {
    margin: 30px 0 20px;
    height: 150px;
    width: 150px;
  }

  span {
    text-align: center;
    margin-bottom: 20px;
  }
}

.manageBusiness {
  background-image: linear-gradient(
    107deg,
    #e5e5e5 -7%,
    #6e9db0 5%,
    #0e5b7c 80%
  );
  border-top-left-radius: 0;
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 100%;
  padding-bottom: 100px;
  transition: 300ms ease-in-out;
  @media (min-width: $lg) {
    grid-template-columns: 50% 50%;
    border-top-left-radius: 350px;
    margin-top: -100px;
  }

  svg {
    height: 200px;
    width: 200px;
    transition: 300ms ease-in-out;
    @media (min-width: $lg) {
      height: 350px;
      width: 350px;
    }
  }
}

.signUpFooter {
  background: radial-gradient(circle at 50% 30%, #819cb5, #005679);

  .signUpContainer {
    width: 98%;
    @media (min-width: $xl) {
      width: 50%;
      max-width: 900px;
    }
  }
}
