.listIcon {
  :global(svg) {
    margin-top: 4px;
  }
}

.logo {
  height: 30px;
}

:global(.popover) {
  :global(.arrow) {
    display: none !important;
  }
  :global(.popover-body) {
    border-radius: 0.3rem !important;
  }
  border-color: #fff !important;
}
