@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.addressInline {
    .addressList {
        height: 300px;
    }
}

.readOnlyAddress {
    .addressLine {
        .comma {
            margin-right: 3px;
        }
    }

    .addressLine:last-child {
        .comma {
            display: none;
        }
    }
}

.readOnlyButtons {
    .readOnlyButton {
        margin-right: 1rem;
        padding: 0;
    }
}

.loading {
    // background-image: url("loading.gif");
    background-size: 25px 25px;
    background-position: right center;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 7px);
    padding-inline-end: 40px;
}

.addressModal {
    height: 700px;
    display: grid;

    .addressList {
        overflow-y: auto;
    }
}

@include media-breakpoint-down(sm) {
    .addressModalContainer {
        display: grid;
        margin: 0 !important;
        max-width: 100vw !important;
        &::before {
            display: none;
        }

        .addressModal {
            height: 100%;
        }
    }
}

.addressModalSearch {
    grid-template-rows: auto auto 1fr auto;
}

.searchButtons {
    display: flex;
    width: 100%;

    .filler {
        flex: 1 1 auto;
    }
}

.searchButtonsModal {
    display: flex;
    flex-direction: column;

    button {
        width: 100%;
        margin-top: 8px;
    }

    .filler {
        display: none;
    }
}

.addressModalManual {
    > div {
        display: grid;
        grid-template-rows: auto auto auto auto 1fr auto;
    }

    button {
        width: 100%;
    }
}

.results {
    display: grid;

    .resultRow {
        display: grid;
        grid-template-columns: 0.5fr 1fr auto;

        .postCode,
        .address {
            display: flex;
            flex: 1;
            align-items: center;
        }

        .action button {
            height: 100%;
        }

        border-bottom: 1px solid #ddd;

        &:last-child {
            border-bottom: none;
        }
    }
}
