@import "../../Styles/theme";

.container {
  min-width: 18px;
  min-height: 18px;
  position: relative;
  color: $main-text;
  margin: 0 12px;
  border-radius: 50%;

  .justLimited-checkbox {
    outline: none;
    min-width: 18px;
    min-height: 18px;

    label {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding: 0 12px;
      cursor: pointer;
      user-select: none;
      color: inherit;
      margin: 0;
      transition: 300ms ease-in-out;

      span {
        margin: 0 8px;
        display: inline-block;
      }

      &:hover {
        color: lighten($main-text, 15%);

        &:before {
          border: 1px solid $brand;
        }
      }

      &:before,
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        transition: 300ms ease-in-out;
      }

      &:before {
        width: 18px;
        height: 18px;
        background-color: $light-border;
        transform: translate(-50%, -50%);
      }

      &:after {
        width: 12px;
        height: 12px;
        background-color: #0e5b7c;
        transform: scale(0, 0);
      }
    }

    &.left label:before,
    &.left label:after {
      left: -2px;
    }

    &.right {
      label {
        right: 20px;

        &:before,
        &:after {
          right: -20px;
        }
      }
    }

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    input[type="checkbox"]:focus + label:before {
      border: 1px solid $brand;
      outline: none;
    }

    &.right input[type="checkbox"]:checked + label:after {
      transform: translate(-100%, -50%);
    }

    &.left input[type="checkbox"]:checked + label:after {
      transform: translate(-50%, -50%);
    }

    input[type="checkbox"]:disabled + label {
      opacity: 0.65;
      cursor: default;
    }

    input[type="checkbox"]:disabled + label:before,
    input[type="checkbox"]:disabled + label:after {
      cursor: default;
      border: none;
    }
  }
  .proFormations-checkbox {
    outline: none;
    min-width: 18px;
    min-height: 18px;

    label {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding: 0 12px;
      cursor: pointer;
      user-select: none;
      color: inherit;
      margin: 0;
      transition: 300ms ease-in-out;

      span {
        margin: 0 8px;
        display: inline-block;
      }

      &:hover {
        color: lighten($main-text, 15%);

        &:before {
          border: 1px solid $brand;
        }
      }

      &:before,
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        transition: 300ms ease-in-out;
      }

      &:before {
        width: 18px;
        height: 18px;
        background-color: $light-border;
        transform: translate(-50%, -50%);
      }

      &:after {
        width: 12px;
        height: 12px;
        background-color: #0e5b7c;
        transform: scale(0, 0);
      }
    }

    &.left label:before,
    &.left label:after {
      left: -2px;
    }

    &.right {
      label {
        right: 20px;

        &:before,
        &:after {
          right: -20px;
        }
      }
    }

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    input[type="checkbox"]:focus + label:before {
      border: 1px solid $brand;
      outline: none;
    }

    &.right input[type="checkbox"]:checked + label:after {
      transform: translate(-100%, -50%);
    }

    &.left input[type="checkbox"]:checked + label:after {
      transform: translate(-50%, -50%);
    }

    input[type="checkbox"]:disabled + label {
      opacity: 0.65;
      cursor: default;
    }

    input[type="checkbox"]:disabled + label:before,
    input[type="checkbox"]:disabled + label:after {
      cursor: default;
      border: none;
    }
  }
  .suaz-checkbox {
    outline: none;
    min-width: 18px;
    min-height: 18px;

    label {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding: 0 12px;
      cursor: pointer;
      user-select: none;
      color: inherit;
      margin: 0;
      transition: 300ms ease-in-out;

      span {
        margin: 0 8px;
        display: inline-block;
      }

      &:hover {
        color: lighten($main-text, 15%);

        &:before {
          border: 1px solid $brand;
        }
      }

      &:before,
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        transition: 300ms ease-in-out;
      }

      &:before {
        width: 18px;
        height: 18px;
        background-color: $light-border;
        transform: translate(-50%, -50%);
      }

      &:after {
        width: 12px;
        height: 12px;
        background-color: $brand;
        transform: scale(0, 0);
      }
    }

    &.left label:before,
    &.left label:after {
      left: -2px;
    }

    &.right {
      label {
        right: 20px;

        &:before,
        &:after {
          right: -20px;
        }
      }
    }

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    input[type="checkbox"]:focus + label:before {
      border: 1px solid $brand;
      outline: none;
    }

    &.right input[type="checkbox"]:checked + label:after {
      transform: translate(-100%, -50%);
    }

    &.left input[type="checkbox"]:checked + label:after {
      transform: translate(-50%, -50%);
    }

    input[type="checkbox"]:disabled + label {
      opacity: 0.65;
      cursor: default;
    }

    input[type="checkbox"]:disabled + label:before,
    input[type="checkbox"]:disabled + label:after {
      cursor: default;
      border: none;
    }
  }
}
