@import "../../Styles/theme";

.modalTitle {
    margin-bottom: 40px;

    h3 {
        margin-left: 10px;
        font-weight: bold;
        font-size: 1.3rem;
        margin-bottom: 0;
        @media (min-width: $sm) {
            font-size: 1.75rem;
        }
    }
}
