@import "../../../Styles/theme";

.dropzone {
    border-radius: 5px;
    border: dashed 1px $light-border;
    cursor: pointer;

    &:hover,
    div:focus {
        transition: 300ms ease-in-out;
        border: dashed 1px $main-text;
        outline: none;
    }
    &:focus {
        outline: none;
    }
}
