.simpleScrollbars {
    --scrollbarBG: transparent;
    --thumbBG: rgba(0, 0, 0, 0.2);

    display: block;
    width: calc(100%); // calculate with model body padding
    padding-right: 20px;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.simpleScrollbars::-webkit-scrollbar {
    width: 6px;
}

.simpleScrollbars::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}

.simpleScrollbars::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
    max-height: 30px;
}

.simpleScrollbarsOverflowX {
    overflow-x: auto;
}

.simpleScrollbarsOverflowY {
    overflow-y: auto;
}
