@import "../../Styles/theme";

.bscLogo {
  height: 60px;
  width: 80px;
  fill: $bscBrand;
}

.passwordToggle {
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  outline: none;
  display: flex;
}

.formContainer {
  width: 100%;
  @media (min-width: $lg) {
    width: 75%;
  }

  :global(.input-group),
  input {
    width: 100%;
    @media (min-width: $lg) {
      width: 50%;
      margin: auto;
    }
  }
}

.termsContainer {
  font-size: 14px;
}

.bscTextBox {
  &:focus {
    border-color: $bscBrand !important;
    box-shadow: 0 0 0 0.2rem rgba(16, 113, 153, 0.25) !important;
  }
}
