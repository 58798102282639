@import "../../Styles/theme";

.summaryContainer {
  display: grid;
  grid-gap: 10px;
  padding: 10px 0;

  .title {
    font-weight: 600;
  }

  button {
    border: none;
    background-color: white;
  }

  .description {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .address {
    white-space:pre;
  }
}

@include media-breakpoint-up(md) {
  .summaryContainer {
    grid-template-columns: 200px 1fr auto;
  }
}

@include media-breakpoint-down(sm) {
  .summaryContainer {
    grid-template-areas:
      "title title"
      "description edit-button";
    grid-template-columns: 1fr auto;
  }

  .title {
    grid-area: title;
  }

  .description {
    grid-area: description;
  }

  .editButton {
    grid-area: edit-button;
  }
}
