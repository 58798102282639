@import "../../Styles/theme";

.container {
    min-width: 25px;
    min-height: 25px;
    position: relative;
    margin-right: 25px;

    .suaz-radio {
        outline: none;
        min-width: 25px;
        min-height: 25px;

        &.disabled {
            label {
                cursor: default;
            }
        }

        label {
            display: inline-block;
            position: relative;
            cursor: pointer;
            user-select: none;
            color: $main-text;
            margin: 0;
            font-weight: 600;

            span {
                margin-left: 45px;
            }

            &:hover {
                color: $brand;
            }

            &:before,
            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                border-radius: 50%;
            }

            &:before {
                width: 24px;
                height: 24px;
                border: 2px solid #d8d8d8;
                background-color: #fff;
                transition: border 0.15s ease-in-out;

                &:hover {
                    border: 1px solid $brand !important;
                }
            }

            &:after {
                width: 16px;
                height: 16px;
                background-color: $brand;
                transform: scale(0, 0);
                transition: transform 0.1s;
                top: 12px;
                left: 12px;
            }
        }

        input[type="radio"] {
            opacity: 0;
            position: absolute;
            left: -1000px;
            z-index: 1;
        }

        input[type="radio"]:focus + label:before {
            outline: none;
        }

        input[type="radio"]:checked + label:after {
            transform: translate(-50%, -50%);
            top: 12px;
            left: 12px;
        }

        input[type="radio"]:disabled + label {
            opacity: 0.65;
        }

        input[type="radio"]:disabled + label:before {
            cursor: default;
        }
    }
    .justLimited-radio {
        outline: none;
        min-width: 25px;
        min-height: 25px;

        &.disabled {
            label {
                cursor: default;
            }
        }

        label {
            display: inline-block;
            position: relative;
            cursor: pointer;
            user-select: none;
            color: $main-text;
            margin: 0;
            font-weight: 600;

            span {
                margin-left: 45px;
            }

            &:hover {
                color: #0e5b7c;
            }

            &:before,
            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                border-radius: 50%;
            }

            &:before {
                width: 24px;
                height: 24px;
                border: 2px solid #d8d8d8;
                background-color: #fff;
                transition: border 0.15s ease-in-out;

                &:hover {
                    border: 1px solid #0e5b7c !important;
                }
            }

            &:after {
                width: 16px;
                height: 16px;
                background-color: #0e5b7c;
                transform: scale(0, 0);
                transition: transform 0.1s;
                top: 12px;
                left: 12px;
            }
        }

        input[type="radio"] {
            opacity: 0;
            position: absolute;
            left: -1000px;
            z-index: 1;
        }

        input[type="radio"]:focus + label:before {
            outline: none;
        }

        input[type="radio"]:checked + label:after {
            transform: translate(-50%, -50%);
            top: 12px;
            left: 12px;
        }

        input[type="radio"]:disabled + label {
            opacity: 0.65;
        }

        input[type="radio"]:disabled + label:before {
            cursor: default;
        }
    }
    .proFormations-radio {
        outline: none;
        min-width: 25px;
        min-height: 25px;

        &.disabled {
            label {
                cursor: default;
            }
        }

        label {
            display: inline-block;
            position: relative;
            cursor: pointer;
            user-select: none;
            color: $main-text;
            margin: 0;
            font-weight: 600;

            span {
                margin-left: 45px;
            }

            &:hover {
                color: #0e5b7c;
            }

            &:before,
            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                border-radius: 50%;
            }

            &:before {
                width: 24px;
                height: 24px;
                border: 2px solid #d8d8d8;
                background-color: #fff;
                transition: border 0.15s ease-in-out;

                &:hover {
                    border: 1px solid #0e5b7c !important;
                }
            }

            &:after {
                width: 16px;
                height: 16px;
                background-color: #0e5b7c;
                transform: scale(0, 0);
                transition: transform 0.1s;
                top: 12px;
                left: 12px;
            }
        }

        input[type="radio"] {
            opacity: 0;
            position: absolute;
            left: -1000px;
            z-index: 1;
        }

        input[type="radio"]:focus + label:before {
            outline: none;
        }

        input[type="radio"]:checked + label:after {
            transform: translate(-50%, -50%);
            top: 12px;
            left: 12px;
        }

        input[type="radio"]:disabled + label {
            opacity: 0.65;
        }

        input[type="radio"]:disabled + label:before {
            cursor: default;
        }
    }
}
